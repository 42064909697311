import "./consent-mode/ucEventListener";

import { insertGtag } from "./sgtm";

import * as datalayer from "./datalayer/data-layer";
import * as errorlogging from "./errorlogging/error-logging";
import "./errorlogging/plugin-shell";
import "./errorlogging/sentry-element";
import "./callbacks/call-backs-app";
import "./callbacks/element-callback-app";
import "./callbacks/element-app-hide";
import "./helpers/dialog";
import "./tags";
import "./product-attribution";

export { datalayer, errorlogging };

insertGtag();